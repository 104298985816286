<template>
	<div class="policy_wrap">
		<h2 class="policy">개인정보 취급 방침</h2>
		<div class="list_wrap">
			<ul>
				<li><a href="#article01">제1조 총칙</a></li>
				<li><a href="#article02">제2조 개인정보의 수집항목 및 수집/이용 목적</a></li>
				<li><a href="#article03">제3조 개인정보의 수집방법</a></li>
				<li><a href="#article04">제4조 개인정보의 보유 및 이용기간</a></li>
				<li><a href="#article05">제5조 개인정보의 파기절차 및 방법</a></li>
				<li><a href="#article06">제6조 개인정보 처리의 위탁</a></li>
				<li><a href="#article07">제7조 이용자 및 법정대리인의 권리와 그 행사 방법</a></li>
			</ul>
			<ul>
				<li><a href="#article08">제8조 아동의 개인정보보호</a></li>
				<li><a href="#article09">제9조 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항</a></li>
				<li><a href="#article10">제10조 개인정보의 기술적, 관리적 보호</a></li>
				<li><a href="#article11">제11조 개인정보 보호책임 부서 및 연락처</a></li>
				<li><a href="#article12">제12조 개인정보 열람청구</a></li>
				<li><a href="#article13">제13조 권익침해 구제방법</a></li>
			</ul>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article01">제1조 총칙</h3>
			<div class="text_bundle">
				<p>
					주식회사 아이디어 콘서트(이하 ‘회사’)가 운영하는 오디오북은 수집 및 활용되는 고객의 개인정보의
					효과적인 관리와 안전한 보호를 위하여 최선의 노력을 다하고 있습니다.
				</p>
				<p>
					회사는 개인정보 보호 관련 주요 법률인 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
					법률(이하 “정보통신망법”이라고 합니다)을 비롯한 모든 개인정보 보호에 관련 법률 규정 및 국가기관 등이
					제정한 고시, 훈령, 지침 등을 준수합니다.
				</p>
				<p>
					본 개인정보처리방침은 회사의 서비스를 이용하는 회원에 대하여 적용되며, 회원이 제공하는 개인정보가
					어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위하여 회사가 어떠한 조치를 취하고 있는지
					알립니다. 또한 개인정보와 관련하여 회사와 회원간의 권리 및 의무 관계를 규정하여 회원의
					‘개인정보자기결정권’을 보장하는 수단이 됩니다.
				</p>
				<p class="p_point">[개인정보의 수집·이용에 대한 동의]</p>
				<ol class="fst">
					<li>
						회사는 적법하고 공정한 방법에 의하여 서비스 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를
						수집하며 이용자의 개인 식별이 가능한 개인정보를 수집하기 위하여 회원가입시 개인정보수집·이용
						동의에 대한 내용을 제공하고 회원이 '동의' 버튼을 클릭하면 개인정보 수집·이용에 대해 동의한
						것으로 봅니다.
					</li>
					<li>
						개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 당해 개인을 알아볼 수
						있는 부호, 문자, 음성, 음향, 영상 및 생체 특성 등에 관한 정보(당해 정보만으로는 특정 개인을
						알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.
					</li>
					<li>
						회사의 개인정보처리방침은 관련 법령 및 내부 운영 방침의 변경에 따라 개정될 수 있습니다.
						개인정보처리방침이 개정되는 경우에는 시행일자 등을 부여하여 개정된 내용을 홈페이지에 지체 없이
						공지합니다.
					</li>
					<li>
						영업의 전부 또는 일부를 양도하거나 합병 등으로 개인정보를 이전하는 경우 서면 전자우편 등을
						통하여 고객님께 개별적으로 통지하고, 회사의 과실 없이 고객님의 연락처를 알 수 없는 경우에
						해당하여 서면, 전자우편 등으로 통지할 수 없는 경우에는 홈페이지, 첫 화면에서 식별할 수 있도록
						표기하여 30일 이상 그 사실을 공지합니다. 단, 천재지변 등 정당한 사유로 홈페이지 게시가 곤란한
						경우에는 2곳 이상의 중앙일간지(고객님의 대부분이 특정 지역에 거주하는 경우에는 그 지역을
						보급구역으로 하는 일간지로 할 수 있습니다.)에 1회 이상 공고하는 것으로 갈음합니다.
					</li>
				</ol>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article02">제2조 개인정보의 수집항목 및 수집/이용 목적</h3>
			<div class="text_bundle">
				<p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
				<ol class="fst">
					<li>
						서비스 제공에 관한 계약 이행 유료 서비스 제공에 따른 요금정산 콘텐츠 제공, 유료 서비스 이용에
						대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심
					</li>
					<li>
						회원관리 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용
						방지, 중복가입확인, 가입의사 확인, 연령확인, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의
						여부 확인 및 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 계정 정보 변경 등
						고지사항 전달
					</li>
					<li>
						마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및
						광고 게재, 서비스 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 및 광고성
						정보와 참여기회 제공
					</li>
					<li>
						맞춤 서비스 제공, 서비스 유지·관리 및 품질 향상, 신규 서비스 요소 발굴 및 개선 등 회사는 본
						서비스의 일환으로 회원에게 앱푸시 등의 방법으로 맞춤형 콘텐츠를 추천할 수 있습니다. 회원은
						언제든지 앱푸시 설정을 통하여 이를 거절할 수 있습니다.
					</li>
				</ol>
				<p class="p_point">[개인정보의 수집·이용에 대한 동의]</p>
				<div class="table_wrap">
					<table>
						<thead>
							<tr>
								<th width="20%">목적</th>
								<th>항목</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>사용자인증</th>
								<td>
									오디오북 ID, ID 인증 이메일 주소, Google ID, 휴대폰 번호, 이메일 주소, 생년월일,
									닉네임
								</td>
							</tr>
							<tr>
								<th>서비스 이용</th>
								<td>서비스 이용시간/정지 기록, 접속 로그, 쿠키 정보, 생년월일</td>
							</tr>
						</tbody>
					</table>
					<p>
						※ 오디오북 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의 거부시 서비스 이용이 제한될
						수 있습니다.
					</p>
				</div>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article03">제3조 개인정보의 수집방법</h3>
			<div class="text_bundle">
				<p class="p_point">[수집하는 개인정보의 항목범위 및 수집 방법]</p>
				<p>
					회원가입, 상담, 서비스 신청 등 서비스 제공 및 계약이행을 위해 회원가입 시점에 회사가 회원으로부터
					수집하는 개인정보는 아래와 같습니다.
				</p>
				<p>
					- 아이디(이메일 주소), 비밀번호, sns를 통한 가입 시 이메일, 이름, 생년월일, 연령, 성별, 프로필 사진,
					식별token, 회원번호(sns 연동 회원에 한함)를 수집하며 만약 회원의 생년월일이 만14세 미만 아동일
					경우에는 법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(DI), 휴대전화번호)를
					추가로 수집합니다.
				</p>
				<p>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
				<ol class="fst">
					<li>
						회사는 회원이 유료 서비스를 이용하고자 하는 경우 결제 시점에 아래와 같이 결제에 필요한 정보를
						수집할 수 있습니다. - 결제수단 소유자 정보(이름), 카드정보, 휴대전화번호, 유선전화번호, 결제건별
						발행되는 거래 ID, 결제상품코드
					</li>
					<li>
						회사는 회원이 이벤트, 프로모션에 참여하는 경우 아래의 정보를 수집할 수 있습니다.
						<div>- 이름, 이메일 주소, 휴대전화번호, 주소, 생년월일</div>
					</li>
					<li>
						연령에 따른 콘텐츠 및 상품의 이용/구매 제한, 서비스 부정 이용 방지를 위해 본인인증이 진행되며
						이름, 생년월일, 성별, 내/외국인 여부, 휴대전화번호, 연계정보(CI), 중복확인정보(DI)를 수집할 수
						있습니다.
					</li>
					<li>현금 환불 시 예금주명, 계좌은행명, 계좌번호, 관계증명서류(필요시)를 수집할 수 있습니다.</li>
					<li>
						고객센터로 문의 시 정확한 안내를 위해 이메일 주소, 휴대전화번호, 이름, 생년월일, 결제정보 등의
						정보를 수집할 수 있습니다.
					</li>
					<li>
						회원 대상 맞춤형 추천 서비스 제공을 목적으로 이용자를 특정할 수 없는 이용한 서비스의 정보,
						접속시간 및 빈도 등의 행태 정보를 수집할 수 있습니다.
					</li>
				</ol>
				<p>서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</p>
				<ol class="fst">
					<li>
						PC : PC MacAddress, PC 사양정보, 브라우저 정보, 기타 서비스 이용 시 사용되는 프로그램 버전 정보
					</li>
					<li>
						휴대전화(스마트폰) &amp; 스마트OS 탑재 모바일 기기(Tablet PC 등) : 모델명, 기기별
						고유번호(UDID,IMEI 등), OS정보, 이동통신사, 구글/애플 광고 ID
					</li>
					<li>기타 정보 : 서비스 이용(정지) 기록, 접속 로그, 쿠키, 접속 IP정보</li>
				</ol>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article04">제4조 개인정보의 보유 및 이용기간</h3>
			<div class="text_bundle">
				<p>
					1. 회사는 고객님의 개인정보를 고객님께 서비스를 제공하는 기간 또는 분쟁처리 기간 동안에 한하여
					보유하고 이를 이용합니다.
				</p>
				<ul class="scd">
					<li>서비스 가입기간(가입일 ~ 해지일)</li>
					<li>요금정산, 요금 과오납 등 분쟁 대비를 위해 해지 또는 요금완납 후 6개월까지</li>
					<li>
						요금관련 분쟁 등 서비스 이용과 관련된 민원/분쟁이 계속될 경우에는 민원/분쟁 해결 시까지 보유(단,
						관계 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)
					</li>
				</ul>
			</div>
			<div class="text_bundle">
				<p>
					2. 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및
					보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안
					고객의 개인정보를 보유하며, 그 기간은 다음과 같습니다.
				</p>
				<h4>개인정보 보존 근거, 대상, 기간</h4>
				<div class="table_wrap">
					<table>
						<thead>
							<tr>
								<th width="20%">보존근거</th>
								<th>보존 대상</th>
								<th width="20%">보존 기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th rowspan="2" scope="row">상법</th>
								<td>상업장부와 영업에 관한 중요서류에 포함된 개인정보</td>
								<td>10년</td>
							</tr>
							<tr>
								<td>전표 또는 이와 유사한 서류에 포함된 개인정보</td>
								<td>5년</td>
							</tr>
							<tr>
								<th scope="row">국세기본법</th>
								<td>
									성명, 주민번호, 전화번호, 청구지 주소, 요금납부내역 등 거래에 관한 장부 및 증거서류
								</td>
								<td>5년</td>
							</tr>
							<tr>
								<th rowspan="2" scope="row">통신비밀보호법</th>
								<td>
									통신사실확인자료 제공에 필요한 성명, 주민번호, 전화번호 및 가입자의 전기통신일시,
									전기통신개시ㆍ종료시간, 발ㆍ착신 통신번호 등 상대방의 가입자번호, 사용도수,
									발신기지국의 위치정보
								</td>
								<td>12개월<br />단, 시외/시내전화 역무와 관련된 자료 : 6개월</td>
							</tr>
							<tr>
								<td>
									컴퓨터통신 또는 인터넷의 로그기록자료, 정보통신기기의 위치를 확인할 수 있는 접속지의
									추적자료
								</td>
								<td>3개월</td>
							</tr>
							<tr>
								<th rowspan="4" scope="row">전자상거래 등에서의소비자보호에 관한 법률</th>
								<td>계약 또는 청약철회 등에 관한 기록</td>
								<td>5년</td>
							</tr>
							<tr>
								<td>대금결제 및 재화 등의 공급에 관한 기록</td>
								<td>5년</td>
							</tr>
							<tr>
								<td>3년</td>
								<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
							</tr>
							<tr>
								<td>표시/광고에 관한 기록</td>
								<td>6개월</td>
							</tr>
							<tr>
								<th scope="row">신용정보의 이용 및 보호에 관한 법률</th>
								<td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
								<td>3년</td>
							</tr>
						</tbody>
					</table>
					<p>
						※ 오디오북 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의 거부시 서비스 이용이 제한될
						수 있습니다.
					</p>
				</div>
			</div>
		</div>

		<div class="text_bundle_wrap">
			<h3 id="article05">제5조 개인정보의 파기절차 및 방법</h3>
			<div class="text_bundle">
				<p>
					주식회사 아이디어 콘서트(이하 ‘회사’)가 운영하는 오디오북은 수집 및 활용되는 고객의 개인정보의
					효과적인 관리와 안전한 보호를 위하여 최선의 노력을 다하고 있습니다.
				</p>
				<p>
					회사는 개인정보 보호 관련 주요 법률인 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
					법률(이하 “정보통신망법”이라고 합니다)을 비롯한 모든 개인정보 보호에 관련 법률 규정 및 국가기관 등이
					제정한 고시, 훈령, 지침 등을 준수합니다.
				</p>
				<p>
					본 개인정보처리방침은 회사의 서비스를 이용하는 회원에 대하여 적용되며, 회원이 제공하는 개인정보가
					어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위하여 회사가 어떠한 조치를 취하고 있는지
					알립니다. 또한 개인정보와 관련하여 회사와 회원간의 권리 및 의무 관계를 규정하여 회원의
					‘개인정보자기결정권’을 보장하는 수단이 됩니다.
				</p>
				<p class="p_point">[개인정보의 수집·이용에 대한 동의]</p>
				<ol class="fst">
					<li>
						회사는 적법하고 공정한 방법에 의하여 서비스 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를
						수집하며 이용자의 개인 식별이 가능한 개인정보를 수집하기 위하여 회원가입시 개인정보수집·이용
						동의에 대한 내용을 제공하고 회원이 '동의' 버튼을 클릭하면 개인정보 수집·이용에 대해 동의한
						것으로 봅니다.
					</li>
					<li>
						개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 당해 개인을 알아볼 수
						있는 부호, 문자, 음성, 음향, 영상 및 생체 특성 등에 관한 정보(당해 정보만으로는 특정 개인을
						알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.
					</li>
					<li>
						회사의 개인정보처리방침은 관련 법령 및 내부 운영 방침의 변경에 따라 개정될 수 있습니다.
						개인정보처리방침이 개정되는 경우에는 시행일자 등을 부여하여 개정된 내용을 홈페이지에 지체 없이
						공지합니다.
					</li>
					<li>
						영업의 전부 또는 일부를 양도하거나 합병 등으로 개인정보를 이전하는 경우 서면 전자우편 등을
						통하여 고객님께 개별적으로 통지하고, 회사의 과실 없이 고객님의 연락처를 알 수 없는 경우에
						해당하여 서면, 전자우편 등으로 통지할 수 없는 경우에는 홈페이지, 첫 화면에서 식별할 수 있도록
						표기하여 30일 이상 그 사실을 공지합니다. 단, 천재지변 등 정당한 사유로 홈페이지 게시가 곤란한
						경우에는 2곳 이상의 중앙일간지(고객님의 대부분이 특정 지역에 거주하는 경우에는 그 지역을
						보급구역으로 하는 일간지로 할 수 있습니다.)에 1회 이상 공고하는 것으로 갈음합니다.
					</li>
				</ol>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article06">제6조 개인정보 처리의 위탁</h3>
			<div class="text_bundle">
				<span class="tit">[개인정보 처리업무 수탁자 및 위탁 업무 내용]</span>

				<div class="table_wrap">
					<table>
						<thead>
							<tr>
								<th width="20%">수탁사</th>
								<th>위탁업무내용</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th rowspan="2" scope="row">-</th>
								<td>
									서비스 운영 및 고객상담업무 위탁운영<br />시스템 구축 및 유지보수,
									STORYBIRD(스토리버드) 부가서비스 제공
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<p>※ 수탁사 리스트는 해당 서비스 변경 및 계약기간에 따라 변경될 수 있습니다.</p>
			<p>
				※ 오디오북 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의 거부시 서비스 이용이 제한될 수
				있습니다.
			</p>
			<span class="tit">[개인정보의 제3자 제공 및 위탁]</span>
			<ul>
				<li>
					1. 회사는 회원의 개인정보를 본 개인정보처리방침에서 명시된 범위를 초과하여 이용하거나 회원의 동의
					없이 본 개인정보처리방침에 명시된 제공 대상자 외의 제3자(타인 또는 타기 업 기관)에 제공하지
					않습니다. 다만, 다음 각호의 어느 하나에 해당하는 경우에는 예외로 합니다.
					<div class="text_indent">
						- 관계법령에 의하여 수사, 재판 또는 행정상의 목적으로 관계기관으로부터 요구가 있을 경우<br />
						- 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법,
						전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 기타 관계법령에서 정한 절차에
						따른 요청이 있는 경우
					</div>
				</li>
				<li>
					2. 영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스제공자의 권리, 의무를 이전 승계하는
					경우 개인 정보보호 관련 회원의 권리를 보장하기 위하여 반드시 그 사실을 회원에게 통지합니다.
				</li>
				<li>
					3. 보다 나은 서비스 제공을 위하여 회사가 귀하의 개인정보를 타 기업 등 제3자에게 제공하는 것이 필요한
					경우 회사는 사전에 제휴사가 누구인지, 제공 또는 공유되어야 하는 개인정보항목이 무엇인지, 제공 또는
					공유되는 개인정보의 이용목적, 그리고 언제까지 공유되며 어떻게 보호 관리되는지에 대하여 회원에게
					고지하여 동의를 구하는 절차를 거칠 것이며, 회원이 동의하지 않는 정보는 제3자에 제공하거나 제3자와
					공유하지 않습니다. 회사는 개인정보처리방침에서 정한 본래의 수집목적 및 이용목적에 반하여 무분별하게
					회원의 개인 정보가 제공되지 않도록 최대한 노력하겠습니다.
				</li>
				<li>
					4. 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁받은 업체가
					정보통신망법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 하고 있습니다.
					회사가 수탁업체에 위탁하는 업무와 관련된 서비스를 이용하지 않는 경우, 이용자의 개인정보가 수탁업체에
					제공되지 않습니다
				</li>
			</ul>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article07">제7조 이용자 및 법정대리인의 권리와 그 행사 방법</h3>
			<div class="text_bundle">
				<p>
					1. 고객님(만 14세 미만 아동의 경우 법정대리인 포함) 께서는 언제든지 개인정보, 개인정보를 이용하거나
					제3자에게 제공한 현황, 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 한 현황(이하 ‘개인정보 등’이라
					합니다)에 대한 열람이나 제공을 요구하실 수 있고, 오류가 있는 경우에는 그 정정을 요구하실 수 있으며,
					개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회를 하실 수 있습니다.
				</p>
				<p>
					2. 고객님께서 가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 탈퇴가
					가능합니다.
				</p>
				<p>
					3. 고객님께서 본인의 개인정보 등에 대한 열람이나 정정을 요구하시거나 개인정보 수집ㆍ이용ㆍ제공 등의
					동의를 철회하시는 경우 고객님의 신분을 증명할 수 있는 주민등록증, 여권, 운전면허증(신형) 등의
					신분증명(사본)을 제시 받아 본인 여부를 확인합니다.
				</p>
				<p>
					4. 고객님의 대리인이 고객님의 개인정보 등에 대한 열람이나 정정을 요구하거나 고객님의 개인정보의
					수집ㆍ이용ㆍ제공에 대한 동의를 철회하는 경우에는 대리 관계를 나타내는 위임장, 명의고객님의
					인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아 적법한 대리인인지 여부를 확인합니다.
				</p>
				<p>
					5. 고객님께서는 개인정보 등의 열람이나 제공을 요청하실 수 있으며, 회사는 이러한 요청에 지체 없이
					필요한 조치를 취합니다.
				</p>
				<p>
					6. 고객님께서 개인정보 등의 오류에 대한 정정을 요청하신 경우에는 지체 없이 그 오류를 정정하거나
					정정하지 못하는 사유를 이용자에게 알리는 등 필요한 조치를 하고, 필요한 조치를 할때까지는 당해
					개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
					처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 다만, 다른 법률에 따라
					개인정보의 제공을 요청받은 경우에는 그 개인정보를 제공하거나 이용할 수 있습니다.
				</p>
				<p>
					7. 회사는 고객님의 요청에 의해 해지 또는 삭제된 개인정보를 “개인정보의 보유 및 이용기간”에 명시된
					바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article08">제8조 아동의 개인정보보호</h3>
			<div class="text_bundle">
				<p>
					1. 회사는 만 14세 미만 아동(이하 '아동')의 개인정보에 대하여 수집ㆍ이용ㆍ제공 등의 동의를 받는 경우
					법정대리인의 동의를 얻도록 하고 있습니다.
				</p>

				<p>
					2. 회사는 법정대리인의 동의를 얻기 위하여 법정대리인의 성명, 연락전화번호 등 필요한 최소한의 정보를
					요구할 수 있습니다. 이 경우 개인정보의 수집•이용 또는 제공 목적 및 법정대리인의 동의가 필요하다는
					취지를 아동이 쉽게 이해 할 수 있는 평이한 표현으로 아동에게 고지 합니다.
				</p>
				<p>
					3. 법정대리인의 동의를 얻기 위하여 수집한 법정대리인의 개인정보를 해당 법정대리인의 동의 여부를
					확인하는 목적 외의 용도로 이를 이용하거나 제3자에게 제공하지 않습니다.
				</p>
				<p>
					4. 만14세 미만으로 표시된 아동에게는 전자우편 형식의 광고나 제안서를 보내지 않으며 게임, 경품 및
					이벤트를 이용하여 더 많은 정보를 공개하도록 유도하지 않습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article09">제9조 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항</h3>
			<div class="text_bundle">
				<p>
					회사는 홈페이지 운영에 있어 필요 시 고객님의 정보를 찾아내고 저장하는 '쿠키(Cookie)'를 운용합니다.
					쿠키는 회사의 웹사이트를 운영하는데 이용되는 서버가 고객님의 브라우저에 보내는 아주 작은 텍스트
					파일로서 고객님의 컴퓨터 하드디스크에 저장됩니다. 고객님께서는 웹브라우저의 보안 정책을 통해 쿠키에
					의한 정보수집의 허용 거부 여부를 결정하실 수 있습니다.
				</p>
				<ul>
					<li>
						1. 쿠키에 의해 수집되는 정보 및 이용 목적
						<ul>
							<li>
								가. 수집 정보 : ID(STORYBIRD(스토리버드) ID), 접속IP, 접속로그, 이용 컨텐츠 등 서비스
								이용정보
							</li>
							<li>
								나. 이용목적
								<ul>
									<li>(1) 개인의 관심 분야에 따라 차별화 된 정보를 제공</li>
									<li>
										(2) 회원과 비회원의 접속 빈도 또는 머문 시간 등을 분석하여 이용자의 취향과
										관심분야를 파악하여 타겟 (target) 마케팅에 활용
									</li>
									<li>
										(3) 서비스 이용 내역을 추적하여 분석을 통한 추후 개인 맞춤 서비스를 제공 및
										서비스 개편 등의 척도로 활용
									</li>
									<li>(4) 유료서비스 이용 시 이용기간 안내 등</li>
									<li>
										(5) 회원에게 맞춤형 광고를 제공하기 위하여 회사는 웹 브라우저에서는 ‘쿠키’,
										모바일 앱에서는 구글/애플 광고 ID를 수집하여 사용합니다.
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						2. 고객님은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써
						쿠키에 의한 정보 수집 수준의 선택을 조정하실 수 있습니다.
						<div class="text_indent">
							- [PC웹] 익스플로러 : 도구&gt;인터넷옵션&gt;개인정보&gt;고급 에서 쿠키 설정<br />
							- [PC웹] 크롬 : 설정&gt;쿠키 및 기타 사이트 데이터 에서 쿠키 설정<br />
							- [안드로이드폰] Google 설정 &gt; 광고 &gt; 광고 맞춤설정 선택 또는 해제<br />
							- [아이폰] 설정 &gt; 개인정보보호 &gt; 광고 &gt; 광고 추적 제한
						</div>
						위에 제시된 메뉴를 통해 쿠키가 저장될 때마다 확인을 하거나, 아니면 모든 쿠키의 저장을 거부할
						수도 있습니다. 단, 고객님께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
						있습니다.
					</li>
				</ul>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article10">제10조 개인정보의 기술적, 관리적 보호</h3>
			<div class="text_bundle">
				<p>
					회사는 고객님의 개인정보가 분실, 유출, 위조•변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과
					같은 기술적•관리적 대책을 마련하고 있습니다.
				</p>

				<span class="tit">[기술적 보호대책]</span>
				<ul>
					<li>
						1. 고객님의 개인정보를 암호화하여 저장하며, 정보통신망을 통해 고객님의 개인정보 및 인증정보를
						송수신 하는 경우에는 안전한 보안서버 구축 등의 조치를 통해 암호화 하여 보호하고 있습니다.
					</li>
					<li>
						2. 백신 소프트웨어를 이용하여 컴퓨터바이러스 등에 의한 피해를 방지하기 위한 조치를 취하고
						있습니다. 백신 소프트웨어는 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이
						나오는 즉시 이를 도입, 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
					</li>
					<li>
						3. 고객님의 개인정보에 대한 불법적인 접근을 차단하기 위해, 외부로부터 접근이 통제된 구역에
						시스템을 설치하고, 침입을 차단하는 장치를 이용하고 있으며, 아울러 침입탐지시스템을 설치하여
						24시간 침입을 감시하고 있습니다.
					</li>
				</ul>
				<span class="tit">[관리적 보호대책]</span>
				<ul>
					<li>
						1. 회사는 고객님의 개인정보를 안전하게 처리하기 위한 내부관리계획을 마련하여 소속 직원이 이를
						숙지하고 준수하도록 하고 있습니다.
					</li>
					<li>
						2. 회사는 고객님의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고 접근 권한을 관리하며,
						새로운 보안 기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내 교육과 외부 위탁교육을 통하여
						법규 및 정책을 준수할 수 있도록 하고 있습니다. 고객님의 개인정보를 처리하는 자는 다음과
						같습니다.
						<ul>
							<li>가. 고객님을 직•간접적으로 상대하여 마케팅 업무를 수행하는 자</li>
							<li>
								나. 개인정보보호책임자 및 개인정보보호담당자 등 개인정보 보호 및 개인정보보호 업무를
								담당하는 자
							</li>
							<li>다. 기타 업무상 개인정보의 처리가 불가피한 자</li>
						</ul>
					</li>
					<li>
						3. 전 임직원이 정보보호서약서에 서명하게 함으로써 직원에 의한 정보(개인정보 포함)유출을 사전에
						방지하고, 수시로 개인정보보호 의무를 상기시키며 준수 여부를 감사하기 위한 내부 절차를 마련하여
						시행하고 있습니다.
					</li>
					<li>
						4. 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지도록 하여, 입사 및
						퇴사 후 개인정보 침해사고에 대한 책임을 명확하게 규정하고 있습니다.
					</li>
					<li>5. 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
					<li>
						6. 회사는 고객님 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지
						않습니다. 고객님의 개인정보를 보호하기 위해서 자신의 ID와 비밀번호를 철저히 관리하고 책임을 져야
						합니다.
					</li>
				</ul>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article11">제11조 개인정보 보호책임 부서 및 연락처</h3>
			<div class="text_bundle">
				<p>
					회사는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만 및 문의사항을 처리하기 위하여 아래와 같이
					관련 부서를 지정하여 운영하고 있습니다. 또한 고객님의 의견을 매우 소중하게 생각합니다. 고객님께서
					STORYBIRD(스토리버드) 서비스의 개인정보 관련 문의사항이 있을 경우 아래 개인정보 보호책임자 및
					담당자에게 문의하시면 신속하고 성실하게 답변을 드리겠습니다.
				</p>
				<ul>
					<li>
						1. 개인정보 보호책임 부서 및 연락처
						<span class="tit">[개인정보보호책임자]</span>
						<div class="text_indent">
							- 성명 : 박은별<br />
							- 소속 : 플랫폼운영지원실<br />
							- 이메일 : info@ideaconcert.com
						</div>
						<span class="tit">[개인정보관리담당자]</span>
						<div class="text_indent">
							- 성명 : 박은별<br />
							- 소속 : 플랫폼운영지원실<br />
							- 이메일 : info@ideaconcert.com
						</div>
					</li>
					<li>
						2. 개인정보 보호책임자 역할
						<ul>
							<li>가. 개인정보보호 관련 주요 지침/정책/제도 수립 시행</li>
							<li>나. 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축</li>
							<li>다. 개인정보보호 분야 관리실태 감사 및 지도점검</li>
							<li>라. 개인정보보호 교육 계획 수립 시행</li>
							<li>마. 개인정보처리방침 수립 개정</li>
							<li>바. 개인정보 침해사고 예방 대응</li>
							<li>사. 기타 기타정보보호를 위하여 필요한 업무</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article12">제12조 개인정보 열람청구</h3>
			<div class="text_bundle">
				<p>
					STORYBIRD(스토리버드)는 고객님의 개인정보 열람 청구를 위하여 아래와 같이 관련 부서를 지정하여
					운영하고 있습니다.
				</p>
				<p>
					고객님께서 STORYBIRD(스토리버드) 서비스에 개인정보 열람을 청구하실 경우 아래 부서 담당자에게
					문의하시면 신속하고 성실하게 답변 드리겠습니다.
				</p>
				<span class="tit">[STORYBIRD(스토리버드) 개인정보 열람청구 접수∙처리 부서]</span>
				<div class="text_indent">
					- 성명 : 박은별<br />
					- 소속 : 플랫폼운영지원실<br />
					- 이메일 : info@ideaconcert.com
				</div>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3 id="article13">제13조 권익침해 구제방법</h3>
			<div class="text_bundle">
				<p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
				<ul>
					<li>
						1. 개인정보 침해신고센터(한국인터넷진흥원 운영)
						<ul>
							<li>가. 소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
							<li>
								나. 홈페이지/전화 :
								<a href="http://privacy.kisa.or.kr" target="_blank">http://privacy.kisa.or.kr</a> /
								(국번없이)118
							</li>
						</ul>
					</li>
					<li>
						2. 개인정보 분쟁조정위원회
						<ul>
							<li>가. 소관업무 : 개인정보 분쟁조정신청,집단분쟁조정(민사적 해결)</li>
							<li>
								나. 홈페이지/전화 :
								<a href="http://www.kopico.go.kr" target="_blank">http://www.kopico.go.kr</a> /
								(국번없이)1833-6972
							</li>
						</ul>
					</li>
					<li>
						3. 대검찰청 사이버수사과 :
						<a href="http://www.spo.go.kr" target="_blank">http://www.spo.go.kr</a> / (국번없이) 1301
					</li>
					<li>
						4. 경찰청 사이버안전국 :
						<a href="http://cyberbureau.police.go.kr" target="_blank">http://cyberbureau.police.go.kr</a> /
						(국번없이) 182
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style scoped>
th,
td {
	padding: 7px;
	border: 1px solid lightgrey;
}

.tit {
	display: block;
	margin: 20px 0 10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
	line-height: 1.2;
}

.text_indent {
	padding: 7px 7px 7px 14px;
}

.text_bundle_wrap ul ul {
	padding: 7px 7px 7px 14px;
}
</style>
